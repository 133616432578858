import { RoleLevel } from "./enums";
import _ from "lodash";

export interface INavListItem {
    label: string;
    to: string;
    icon: string;
    children?: INavListItem[];
    roleLevel?: number;
}

export const navList = (role: IRole): INavListItem[] => {
    return [
        {
            label: "Dashboard",
            to: "/dashboard",
            icon: "home",
            roleLevel: RoleLevel.MODERATOR,
        },
        {
            label: "Users",
            to: "/users",
            icon: "team",
            roleLevel: RoleLevel.ADMIN,
        },
        {
            label: "Clients",
            to: "/clients",
            icon: "contacts",
            roleLevel: RoleLevel.MODERATOR,
        },
        { label: "Brands", to: "/brands", icon: "shop" },
        { label: "Transactions", to: "/transactions", icon: "transaction" },
    ].filter((item) => {
        if (!_.isNil(item.roleLevel)) {
            return item.roleLevel <= role.level;
        }

        return true;
    });
};
