import { navList } from "@app/config/sidebar-menu";
import { appStore } from "@app/stores/AppStore";
import { Icon, Layout, Menu } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { useOutsideClick } from "rooks";
import "./layout.scss";
import _ from "lodash";
import { IS_MOBILE } from "@app/config/main";
import { sessionStore } from "@app/stores/SessionStore";
import { RoleLevel } from "@app/config/enums";

const { Sider } = Layout;

export const Sidebar = observer(({ isHidden, hide }: any) => {
    const [showOver, setShowOver] = React.useState(false);
    const isMenuSelected = (path: string) =>
        _.trim(path, "/") === _.trim(location.pathname, "/");
    const ref = React.useRef<any>();

    useOutsideClick(ref, () => {
        if (IS_MOBILE) {
            hide(true);
        }
    });

    React.useEffect(() => {
        if (IS_MOBILE) {
            return;
        }
        const listener = (e: MouseEvent) => {
            if (e.y < 50) {
                return;
            }
            if (e.x < 40 && !showOver) {
                setShowOver(true);
            } else if (e.x > 200 && showOver) {
                setShowOver(false);
            }
        };
        document.body.addEventListener("mousemove", listener);
        return () => document.body.removeEventListener("mousemove", listener);
    }, [showOver]);

    return (
        <Sider
            collapsible
            theme="light"
            style={{ left: isHidden && !showOver ? -200 : 0 }}
        >
            <div ref={ref}>
                <div className="logo">
                    <img
                        src="/img/clopos-logo-pos.png"
                        alt="Clopos Logo"
                        style={{ maxWidth: "100%" }}
                    />
                </div>
                <Menu mode="inline" defaultSelectedKeys={appStore.path}>
                    {navList(sessionStore.role).map((navItem, i) => (
                        <Menu.Item
                            key={navItem.to}
                            // isSelected={isMenuSelected(navItem.to)}
                        >
                            <Link to={navItem.to}>
                                <Icon type={navItem.icon} />
                                <span>{navItem.label}</span>
                            </Link>
                        </Menu.Item>
                    ))}
                </Menu>
            </div>
        </Sider>
    );
});

Sidebar.displayName = "Sidebar";
